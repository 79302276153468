import { Constants } from '~/src/helpers/constants'
import MotionBox from '~/src/components/mui-wrappers/animations/motion/MotionBox'
import { BoxProps } from '@mui/material'
import { MotionProps } from 'framer-motion'
import { PlatformUtils } from '@eo-storefronts/eo-core'

type Props = {
  pathname?: string,
  y?: string | number,
  animateOnDesktop?: boolean,
} & Omit<BoxProps, 'ref'> & MotionProps

const SlideFromBottom = (
  {
    children,
    animateOnDesktop = false,
    pathname,
    y = '100vh',
    transition = { duration: Constants.MOTION_ANIMATION_DURATION },
    ...otherProps
  }: Props
) => {
  if (!animateOnDesktop && PlatformUtils().isDesktop) {
    return <>{children}</>
  }

  return (
    <MotionBox
      key={pathname}
      initial={{ y, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: `-${y}`, opacity: 0 }}
      transition={transition}
      {...otherProps}
    >
      {children}
    </MotionBox>
  )
}

export default SlideFromBottom
